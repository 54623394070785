import React, { useEffect, useState } from "react";
import moment from "moment";
import axiosWithAuth from "../../../../utils/axiosWithAuth";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function JitsiMeet(props) {
  const { product, type } = props;
  const [isMeetingTime, setIsMeetingTime] = useState(false);
  const [jwtToken, setJwtToken] = useState("");
  const [meetingUrl, setMeetingUrl] = useState("");
  const [roomName, setRoomName] = useState(
    product.meta_data
      ?.find((meta) => meta.meta_key === "meet_jitsi")
      ?.meta_value.replaceAll(" ", "")
  );
  const user = useSelector((state) => state.user.user);
  const [date, setDate] = useState(moment().format("HH:mm:ss"));

  const navigate = useNavigate();

  useEffect(() => {
    const fetchMeetingData = async () => {
      try {
        if (product) {
          let startTime, endTime, eventDate;

          if (type === "event") {
            const startTimeMeta = product.meta_data.find(
              (meta) => meta.meta_key === "event_starttime"
            );
            const endTimeMeta = product.meta_data.find(
              (meta) => meta.meta_key === "event_endtime"
            );
            const eventDateMeta = product.meta_data.find(
              (meta) => meta.meta_key === "event_date"
            );

            if (startTimeMeta && endTimeMeta && eventDateMeta) {
              startTime = startTimeMeta.meta_value;
              endTime = endTimeMeta.meta_value;
              eventDate = eventDateMeta.meta_value.split(" ")[0];
            }
          } else if (type === "appointment") {
            if (product && product.start_time) {
              startTime = product.start_time;

              if (product.service && product.service.meta_data) {
                const durationMeta = product.service.meta_data.find(
                  (meta) => meta.meta_key === "service_duration_time"
                );

                if (durationMeta) {
                  const duration = moment.duration(durationMeta.meta_value);
                  endTime = moment(startTime.replace("Z", ""))
                    .add(duration)
                    .format("HH:mm:ss");
                }
              }
              eventDate = product.start_time.split("T")[0];
            }
          }

          if (startTime && endTime && eventDate) {
            const eventStartDateTime = moment(
              `${eventDate} ${startTime}`,
              "YYYY-MM-DD HH:mm:ss"
            );
            const eventEndDateTime = moment(
              `${eventDate} ${endTime}`,
              "YYYY-MM-DD HH:mm:ss"
            );
            const now = moment();

            if (eventStartDateTime.isValid() && eventEndDateTime.isValid()) {
              const minutesToStart = moment
                .duration(eventStartDateTime.diff(now))
                .asMinutes();
              const minutesToEnd = moment
                .duration(eventEndDateTime.diff(now))
                .asMinutes();
              setIsMeetingTime(true);

              if (minutesToStart <= 30 && minutesToEnd >= 0) {
                setIsMeetingTime(true); // Dentro del rango permitido
              } else {
                setIsMeetingTime(false); // Fuera del rango permitido
              }
            }
          }

          if (!jwtToken) {
            getJWT();
          }
        }
      } catch (error) {
        console.error("Error fetching meeting data:", error);
      }
    };

    fetchMeetingData();
  }, [product, type, jwtToken]);

  if (!product) {
    return <div>Loading...</div>;
  }

  const getJWT = async () => {
    const APIURL = `/api/jitsi-meet/accessAuth`;
    const eventData = {
      id: type === "event" ? product.id : product.id,
      owner_id: type === "event" ? product.owner_id : product.service.id,
    };

    try {
      const res = await axiosWithAuth().post(APIURL, {
        roomName: product.meta_data?.find(
          (meta) => meta.meta_key === "meet_jitsi"
        )?.meta_value,
        user: {
          id: user.id,
          name: user.user_login,
          email: user.email,
        },
        eventData: eventData,
        typeEvent: type === "event" ? "event" : "appointment",
      });
      setJwtToken(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleButtonClick = async () => {
    if (roomName) {
      navigate(`/jitsi-meet/${roomName}`, {
        state: { jwtToken, roomName, type: "moderator" },
      });
    } else {
      try {
        const res = await axiosWithAuth().post(
          `${process.env.REACT_APP_BE_API_URL}/api/jitsi-meet/meetings`,
          {
            serviceName: product.service.name,
            date: product.start_time,
            idAppointment: product.id,
          }
        );
        console.log("res", res);
      } catch (error) {
        console.error("Error al crear la reunión:", error);
      }
    }
  };

  // Nueva función para el segundo botón que redirige al servidor Jitsi específico
  const handleJitsiServerButtonClick = () => {
    const domain = process.env.REACT_APP_JITSI_DOMAIN;
    if (roomName && jwtToken) {
      const jitsiServerURL = `https://${domain}/${roomName}?jwt=${jwtToken}`;
      window.open(jitsiServerURL, "_blank");
    } else {
      console.error("Room name or JWT token is missing");
    }
  };

  return (
    <div>
      <button
        onClick={() => handleJitsiServerButtonClick()}
        disabled={!isMeetingTime}
      >
        Join Meeting
      </button>
    </div>
  );
}
