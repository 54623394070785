import React, { useState } from "react";
import { Button, Menu, Dropdown, Tooltip } from "antd";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function FloatingButton() {
  const [visible, setVisible] = useState(true);
  // const [businesses, setBusinesses] = useState([]);

  const businesses = useSelector((state) => state.business.businessList);

  // useEffect(() => {
  //   axios
  //     .get("/api/businesses")
  //     .then((response) => {
  //       setBusinesses(response.data);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching businesses:", error);
  //     });
  // }, []);

  // Generate localstorage to know which business you are managing
  const manageBusiness = (id) => {
    localStorage.setItem("editBusiness", id);

    window.location.replace("/business");
  };

  const menu = (
    <Menu>
      {businesses.map((business) => (
        <Menu.Item
          key={business.url}
          onClick={() => manageBusiness(`${business.id}`)}
        >
          {business.name}
        </Menu.Item>
      ))}
      {console.log("businesses", businesses)}
    </Menu>
  );

  return (
    <div className={`floating-button ${visible ? "" : "hidden"}`}>
      <Tooltip title="Businesses" placement="left">
        <Dropdown overlay={menu} trigger={["click"]} placement="topLeft">
          <Button type="primary" size="large">
            <FontAwesomeIcon icon="fa-solid fa-briefcase" />
          </Button>
        </Dropdown>
      </Tooltip>

      <Button
        className="toggle-button"
        shape="circle"
        onClick={() => setVisible(!visible)}
      >
        {visible ? "-" : "+"}
      </Button>
    </div>
  );
}
