import React from "react";
import { Tabs, Alert } from "antd";
import ServiceInfo from "./ServiceInfo";
import { useParams } from "react-router";
import ListBackBtn from "../../components/ListBackBtn";
import AvailabilityRules from "./AvailabilityRules";
import DayOffAvailabilityRules from "./DayOffAvailavilityRules";

export default function ServiceSettings(props) {
  //* Get the service Data
  const { id } = useParams();
  const { business } = props;

  return (
    <>
      <ListBackBtn
        backURL="/business/services"
        btnTxt="Back To Services List"
      />

      <div className="warning-boxes">
        <Alert
          message="Online Services"
          description={
            <>
              We currently use Jitsi Meet to broadcast online services. Once
              someone schedules an appointment, the "Join Meeting" button will
              be available 30 minutes before the appointment starts. This
              ensures the meeting is registered and active for the scheduled
              date and time.
            </>
          }
          type="warning"
          showIcon
        />
        <br />
        {
          <div className="warning-boxes">
            <Alert
              message="Availibility Rules"
              description="Before your service is published on the site, you MUST create the availibility rules."
              type="warning"
              showIcon
            />
          </div>
        }
      </div>

      <Tabs defaultActiveKey="1" type="card" size="default">
        <Tabs.TabPane tab="Service Information" key="1">
          <ServiceInfo id={id} business={business} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Availability Rules" key="2">
          <AvailabilityRules id={id} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Days Off" key="3">
          <DayOffAvailabilityRules id={id} />
        </Tabs.TabPane>
      </Tabs>
    </>
  );
}
