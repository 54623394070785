//** Import Modules */
import React, { useEffect, useState } from "react";
import { Button, Form, Divider, Input, message } from "antd";

//** Import Components */
import { useDashboardFormData } from "../../../../hooks/useDashboardFormData";
import { useParams } from "react-router";
import LoadingForm from "../../../../common/LoadingForm";
import axiosWithAuth from "../../../../utils/axiosWithAuth";
import ListBackButton from "../../../../common/ListBackButton";

export default function BusinessTypeSettings() {
  // Used to build the form
  const [form] = Form.useForm();

  //* Get the ad Data
  const { id } = useParams();

  // Get categories data
  const [categories, setCategories] = useState();

  useEffect(() => {
    const APIURL = `/api/business/category/${id}`;

    axiosWithAuth()
      .get(APIURL)
      .then((res) => {
        console.log(res);

        setCategories(res.data);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, [id]);

  const [
    fields,
    setFieldData,
    isSubmitting,
    submitForm,
    onFinishFailed,
    loadingForm,
  ] = useDashboardFormData(categories);

  useEffect(() => {
    if (categories && categories.id !== undefined) {
      setFieldData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories]);

  const onFinish = async (values) => {
    const listingData = {
      data: {
        name: values.name,
      },
    };

    try {
      await axiosWithAuth()
        .put(
          `${process.env.REACT_APP_BE_API_URL}/api/business/category/${categories.id}`,
          listingData
        )
        .then(() => {
          let config = {
            content: "Business Updated Successfully",
            style: {
              background: "none",
            },
          };
          message.success(config);
        });
    } catch (error) {
      let config = {
        content: error.response.data.message
          ? error.response.data.message
          : "Generic error occurred",
        style: {
          background: "none",
        },
        duration: 6,
      };
      message.error(config);
      console.log(error);
    }
    // submitForm(values, `/api/categories/update/${categories.id}`);
  };

  return (
    <div>
      <ListBackButton
        backURL="/bizzll-management/business-type-management"
        btnTxt="Back To Business Type List"
      />

      {loadingForm ? (
        <LoadingForm />
      ) : (
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          initialValues={fields}
          onFinishFailed={onFinishFailed}
          onFinish={(values) => onFinish(values)}
          disabled={isSubmitting}
        >
          <Form.Item
            label="Business Type Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Please input a business type name!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Divider />

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isSubmitting}>
              {isSubmitting ? "Saving" : "Save Settings"}
            </Button>
          </Form.Item>
        </Form>
      )}
    </div>
  );
}
