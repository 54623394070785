import React, { useEffect, useState } from "react";
import axiosWithAuth from "../../../utils/axiosWithAuth";
import { useManagementAppointmentsUser } from "../../../hooks/useManagementAppointmentsUser";
import { AppointmentsUserSettings } from "./AppointmentsUserSettings";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, Link } from "react-router-dom";
import { Tabs } from "antd";

export default function ManageAppointmentsUser(props) {
  const { user } = props;

  const [listItems, SetListItems] = useState({
    upcomingAppointments: [],
    pastAppointments: [],
  });

  const dispatch = useDispatch();

  useEffect(() => {
    try {
      axiosWithAuth()
        .get(
          `${process.env.REACT_APP_BE_API_URL}/api/appointments/user/${user.id}`
        )
        .then((res) => {
          SetListItems(res.data);
        });
    } catch (err) {
      console.log(err.response);
    }
  }, [user.id, dispatch]);

  // Custom hook to manage the creation/deletion of user listing
  const hookData = {
    creatorID: user.id,
    user: user,
    itemType: "service",
    type: "service",
  };

  const [ListComponent] = useManagementAppointmentsUser(hookData);

  return (
    <div id="orders-page">
      <div className="content-box">
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Tabs defaultActiveKey="1" type="card" size="default">
                  <Tabs.TabPane tab="Upcoming Appointments" key="1">
                    <ListComponent
                      emptyMessage="Seems like you don't have an appointment listing."
                      listItems={listItems?.upcomingAppointments || []}
                    />
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Past Appointments" key="2">
                    <ListComponent
                      emptyMessage="Seems like you don't have an appointment listing."
                      listItems={listItems?.pastAppointments || []}
                    />
                  </Tabs.TabPane>
                </Tabs>
              </>
            }
            exact
          />

          <Route path="/:id" element={<AppointmentsUserSettings />} exact />
        </Routes>
      </div>
    </div>
  );
}
